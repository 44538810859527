<template>
  <b-container class="usermanagement">
    <b-row>
      <b-col cols="12" md="10" offset-md="1" lg="8" offset-lg="2">
        <h2>Internal User Management</h2>
        <b-button variant="primary" class="mx-1 my-1" @click="showAddUser">Add New User</b-button>
        <b-button variant="primary" class="mx-1 my-1" @click="refreshUsers">Refresh User List</b-button>
        <b-button variant="primary" class="mx-1 my-1" @click="refreshSgGroups">Refresh Groups</b-button>
        <b-list-group class="my-2">
          <b-list-group-item
            v-for="user in this.userList"
            :key="user.username"
            class="d-flex align-items-center"
          >
            <b-avatar class="mr-3" variant="success"></b-avatar>
            <span
              class="mr-auto"
            >{{ user.username }} - {{ user.sg_group ? user.sg_group : "No SG group"}} ({{ user.role }})</span>
            <b-button class="mx-2" @click="showModifyUser(user.username)" variant="outline-primary">
              <b-icon icon="gear"></b-icon>
            </b-button>
            <b-button
              class="mx-2"
              @click="showRemoveUser(user.username)"
              variant="outline-danger"
              :disabled="currentUser.username === user.username"
            >
              <b-icon icon="person-x"></b-icon>
            </b-button>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-modal
      ref="newUserModal"
      title="Create new user"
      hide-header-close
      @ok="addUser"
      :ok-disabled="!newUserFormValid"
    >
      <b-form-group
        label="User email:"
        label-for="newUserEmail"
        :state="newUserEmailState"
        :invalid-feedback="newUserEmailInvalidFeedback"
      >
        <b-form-input id="newUserEmail" v-model="newUsername"></b-form-input>
      </b-form-group>
      <b-form-group
        label="Role:"
        label-for="newUserRole"
        :state="newUserRoleState"
        :invalid-feedback="requiredText"
      >
        <b-form-select id="newUserRole" v-model="newRole" :options="roles"></b-form-select>
      </b-form-group>
      <b-form-group
        label="Shotgrid Group:"
        label-for="newUserGroup"
        description="Optional. Vendors without a group cannot publish."
      >
        <b-form-select id="newUserGroup" v-model="selectedGroup" :options="groupOptions"></b-form-select>
      </b-form-group>
      <b-form-group
        label="Password:"
        label-for="newUserPassword"
        :state="userPasswordState"
        :invalid-feedback="newUserPasswordFeedback"
      >
        <b-form-input id="newUserPassword" v-model="password" type="password"></b-form-input>
      </b-form-group>
      <b-form-group
        label="Password (repeat):"
        label-for="newUserPasswordConfirm"
        :state="userPasswordConfirmState"
        :invalid-feedback="newUserPasswordConfirmFeedback"
      >
        <b-form-input id="newUserPasswordConfirm" v-model="passwordConfirm" type="password"></b-form-input>
      </b-form-group>
    </b-modal>
    <b-modal
      ref="modifyUserModal"
      title="Change user settings"
      hide-header-close
      @ok="modifyUser"
      :ok-disabled="!modifyUserFormValid"
    >
      <p>Leave a field empty to leave it unchanged.</p>
      <label>Username:</label>
      <b-form-input v-model="modifyUsername" disabled></b-form-input>
      <b-form-group
        label="New password:"
        label-for="modifyUserPassword"
        :state="userPasswordState"
        :invalid-feedback="modifyUserPasswordFeedback"
      >
        <b-form-input id="modifyUserPassword" v-model="password" type="password"></b-form-input>
      </b-form-group>
      <b-form-group
        label="New password (repeat):"
        label-for="modifyUserPasswordConfirm"
        :state="userPasswordConfirmState"
        :invalid-feedback="modifyUserPasswordConfirmFeedback"
      >
        <b-form-input id="modifyUserPasswordConfirm" v-model="passwordConfirm" type="password"></b-form-input>
      </b-form-group>
      <label>Shotgrid Group:</label>
      <b-form-select v-model="selectedGroup" :options="groupOptions"></b-form-select>
    </b-modal>
    <b-modal ref="removeUserModal" title="Delete user?" hide-header-close @ok="removeUser">
      <template #modal-ok>Confirm</template>
      <label>Username:</label>
      <b-form-input v-model="deleteUsername" disabled></b-form-input>
    </b-modal>
  </b-container>
</template>
<script>
import api from "@/backendapi";
import { ROLES } from "@/const";
import { defaultToastBody, defaultToastConfig } from "@/util";

// Regex taken from https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
// Not the accepted answer (overly verbose, we just want basic validation on client side)
const EMAIL_RE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default {
  name: "UserManagement",
  data: function() {
    return {
      newUsername: "",
      newRole: null,
      selectedGroup: null,
      modifyUsername: "",
      deleteUsername: "",
      password: "",
      passwordConfirm: "",
      groupOptions: [],
      roles: Object.values(ROLES),
      requiredText: "Required"
    };
  },
  computed: {
    currentUser: function() {
      return this.$store.state.user;
    },
    userList: function() {
      return this.$store.state.userList;
    },
    newUserEmailState() {
      return this._validateUser(this.newUsername);
    },
    newUserEmailInvalidFeedback() {
      return this.newUsername.length === 0
        ? "Required"
        : "Must be a valid email";
    },
    newUserRoleState() {
      return this._validateRole(this.newRole);
    },
    userPasswordState() {
      return this._validatePassword(this.password);
    },
    newUserPasswordFeedback() {
      return this.password.length === 0
        ? "Required"
        : "Must be at least 8 characters";
    },
    userPasswordConfirmState() {
      return this.passwordConfirm === this.password
    },
    newUserPasswordConfirmFeedback() {
      if (this.passwordConfirm.length === 0) {
        return "Required";
      }
      if (this.passwordConfirm != this.password) {
        return "Passwords do not match";
      }
      return "";
    },
    newUserFormValid() {
      return (
        this._validateUser(this.newUsername) &&
        this._validateRole(this.newRole) &&
        this._validatePassword(this.password) &&
        this.password == this.passwordConfirm
      );
    },
    modifyUserPasswordFeedback() {
      if (this.password && this.password.length <= 8) {
        return "Must be at least 8 characters";
      }
      return "";
    },
    modifyUserPasswordConfirmFeedback() {
      if (this.passwordConfirm != this.password) {
        return "Passwords do not match";
      }
      return "";
    },
    modifyUserFormValid() {
      if (!this.password && !this.passwordConfirm){
        return true
      }
      return (
        this._validatePassword(this.password) &&
        this.password == this.passwordConfirm
      );
    }
  },
  methods: {
    _validateUser: function(username) {
      // Checks if username is a valid email
      return EMAIL_RE.test(username);
    },
    _validateRole(role) {
      return role != null;
    },
    _validatePassword: function(password) {
      return password.length >= 8;
    },
    showAddUser: function() {
      this.newUsername = "";
      this.password = "";
      this.passwordConfirm = "";
      this.newRole = null;
      this.selectedGroup = null;
      this.$refs["newUserModal"].show();
    },
    addUser: function() {
      // TODO: This should be in a validation routine, not on submit (closes modal)
      if (this.password != this.passwordConfirm) {
        let toastConfig = defaultToastConfig();
        toastConfig.title = "Passwords mismatch";
        this.$bvToast.toast(
          `Passwords don't match, please try again.`,
          toastConfig
        );
        return;
      }
      let payload = {
        username: this.newUsername,
        password: this.password,
        role: this.newRole,
        sg_group: this.selectedGroup
      };
      this.$store
        .dispatch("registerUser", payload)
        .then(() => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "New user created!";
          toastConfig.variant = "success";
          this.$bvToast.toast(`Created user ${this.newUsername}`, toastConfig);
          this.newUsername = "";
          this.password = "";
          this.passwordConfirm = "";
          this.newRole = null;
          this.selectedGroup = null;
          this.refreshUsers();
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    showModifyUser: function(username, userGroup) {
      this.modifyUsername = username;
      this.password = "";
      this.passwordConfirm = "";
      this.selectedGroup = userGroup;
      this.$refs["modifyUserModal"].show();
    },
    modifyUser: function() {
      // TODO: Verify passwords match (also in navigation)
      let payload = {
        username: this.modifyUsername,
        password: this.password,
        sg_group: this.selectedGroup
      };
      this.$store
        .dispatch("modifyUser", payload)
        .then(() => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = "User password changed!";
          toastConfig.variant = "primary";
          this.$bvToast.toast(`Modified user ${this.newUsername}`, toastConfig);
          this.newUsername = "";
          this.password = "";
          this.passwordConfirm = "";
          this.selectedGroup = null;
          this.refreshUsers();
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    showRemoveUser: function(username) {
      this.deleteUsername = username;
      this.$refs["removeUserModal"].show();
    },
    removeUser: function() {
      this.$store
        .dispatch("deleteUser", this.deleteUsername)
        .then(() => {
          let toastConfig = defaultToastConfig();
          toastConfig.title = `User "${this.deleteUsername}" deleted!`;
          toastConfig.variant = "warning";
          this.$bvToast.toast(`Deleted user ${this.newUsername}`, toastConfig);
          this.deleteUsername = "";
          this.refreshUsers();
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    refreshUsers: function() {
      // TODO: Set loading state to true
      this.$store
        .dispatch("getUsers")
        .then(() => {
          // TODO: Set loading state to false
        })
        .catch(err => {
          this.$bvToast.toast(defaultToastBody(err), defaultToastConfig(err));
        });
    },
    refreshSgGroups: async function() {
      let _groupOpts = [
        { value: null, text: "Select a Shotgrid group", disabled: true },
        { value: "", text: "<no group>" }
      ];
      try {
        let groupNames = await api.getSgGroups(localStorage.token);
        groupNames = groupNames.filter(name => name);
        let foundGroupOpts = groupNames.map(name => {
          if (name) {
            return { value: name, text: name };
          }
        });
        _groupOpts.push(...foundGroupOpts);
        this.groupOptions = _groupOpts;
      } catch (err) {
        this.$bvToast.toast(
          defaultToastBody(err.response),
          defaultToastConfig(err.response)
        );
      }
    }
  },
  components: {},
  created() {},
  mounted() {
    // If we have no users loaded, start the request immediately.
    if (this.$store.state.userList.length === 0) {
      this.refreshUsers();
    }
    this.refreshSgGroups();
  }
};
</script>